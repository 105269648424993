export default class Dropdown {
    constructor(product, storagePlace) {
        this.product = product;
        this.storagePlace = storagePlace;
    }
    toData() {
        return {
            id: this.getId(),
            name: this.getName(),
        };
    }
    getId() {
        const { product, storagePlace } = this;
        const { id } = product;
        return storagePlace ? `${id}_${storagePlace.id}` : id;
    }
    getName() {
        const { product } = this;
        const { name } = product;
        const pesticideNamePart = this.getPesticideNamePart();
        const storageNamePart = this.getStorageNamePart();
        return `${name}${pesticideNamePart}${storageNamePart}`;
    }
    getPesticideNamePart() {
        const { product } = this;
        const { pesticide } = product;
        if (!pesticide)
            return '';
        const { registerNr, registerAdd } = pesticide;
        if (registerNr != null && registerAdd == null)
            return ` (${registerNr})`;
        if (registerNr != null && registerAdd != null)
            return ` (${registerNr}/${registerAdd})`;
        return '';
    }
    getStorageNamePart() {
        const { storagePlace } = this;
        if (!storagePlace)
            return '';
        const { name } = storagePlace;
        if (!name)
            return '';
        return ` (${name})`;
    }
}
