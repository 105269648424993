var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue from 'vue';
import { ENTRY_DIRTY, ENTRY_NEW } from '@/shared/constants';
import { getRestResponseData } from '@/shared/modules/restApiHelpers';
function actions(updateEntries) {
    return {
        insertNewEntry({ commit }, { entry }) {
            return __awaiter(this, void 0, void 0, function* () {
                commit('insert', { entry });
            });
        },
        /**
         * Insert new entry using 'insertNewEntry' and then syncs data to BE.
         *
         * @param dispatch
         * @param entry
         * @return {Promise<void>}
         */
        insertAndSyncNewEntry({ dispatch }, { entry }) {
            return __awaiter(this, void 0, void 0, function* () {
                yield dispatch('insertNewEntry', { entry });
                yield dispatch('syncAll');
            });
        },
        /**
         * Updates an existing entry and then syncs data to BE.
         *
         * @param commit
         * @param dispatch
         * @param entry
         * @return {Promise<void>}
         */
        updateAndSyncEntry({ commit, dispatch }, { entry }) {
            return __awaiter(this, void 0, void 0, function* () {
                commit('updateEntry', { entry });
                yield dispatch('syncAll');
            });
        },
        /** */
        updateEntryByKeyAndValueAndSync({ commit, dispatch }, { key, guid, value }) {
            return __awaiter(this, void 0, void 0, function* () {
                commit('updateEntryByKeyAndValue', { key, guid, value });
                yield dispatch('syncAll');
            });
        },
        /**
         * Sync all dirty and new entries to BE.
         * If a request already runs do not send another one.
         * After each successful request call automatically calls 'syncAll' recursively again, until there are no more dirty/new entries anymore.
         */
        syncAll(context) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                const { state, commit, dispatch } = context;
                if (state.syncing) {
                    yield new Promise((callback) => {
                        commit('addSyncableObserver', callback);
                    });
                    return;
                }
                // Do the debouncing manually (even though a new call will not delay the call anymore)
                // as lodash.debounce doesnt work for async functions out of the box
                commit('syncAllStart');
                yield new Promise((resolve) => {
                    setTimeout(resolve, 100);
                });
                const requestData = Object.values(state.data).filter((entry) => entry.storeStatus === ENTRY_DIRTY || entry.storeStatus === ENTRY_NEW);
                if (requestData.length < 1) {
                    commit('clearSyncableObservers');
                    commit('syncAllFinish');
                    return;
                }
                commit('syncAllAddEntries', requestData);
                let responseData;
                try {
                    // TODO remove storeStatus field?
                    const response = yield updateEntries(requestData, context);
                    responseData = getRestResponseData(response);
                }
                catch (error) {
                    console.error(error);
                    responseData = getRestResponseData(error);
                }
                if (responseData.status === 'success') {
                    commit('syncAllFinish', responseData.data);
                    yield dispatch('syncAll');
                }
                else if (responseData.status === 'partialSuccess') {
                    dispatch('addSyncErrors', { requestData, responseData });
                    commit('syncAllFinish', 
                    // @ts-ignore
                    (_a = responseData.data) === null || _a === void 0 ? void 0 : _a.filter((entry) => { var _a; return ((_a = responseData === null || responseData === void 0 ? void 0 : responseData.errors) === null || _a === void 0 ? void 0 : _a[entry.id]) == null; }));
                    yield dispatch('syncAll');
                }
                else {
                    dispatch('addSyncErrors', { requestData, responseData });
                    commit('clearSyncableObservers');
                    commit('syncAllFinish');
                }
            });
        },
        /**
         * Adds dummy error objects for every entry that was changed if the whole request failed.
         * In case of a 'partialSuccess' errors will be added for every entry that failed.
         *
         * @param commit
         * @param requestData
         * @param responseData
         */
        addSyncErrors({ commit }, { requestData, responseData }) {
            if (responseData.status !== 'partialSuccess') {
                requestData.forEach((entry) => {
                    commit('addSyncError', {
                        guid: entry.id,
                        key: null,
                        errorUserMessage: responseData.errorUserMessage[0],
                    });
                });
                return;
            }
            Object.keys(responseData.errors).forEach((guid) => {
                const error = getRestResponseData(Object.assign({ status: 'error' }, responseData.errors[guid]));
                error.errorUserMessage.forEach((errorUserMessage) => {
                    commit('addSyncError', {
                        guid,
                        key: null,
                        errorUserMessage,
                    });
                });
                if (error.errorCode === 'attributeValidationError') {
                    // @ts-ignore
                    Object.keys(error.errorAttributeUserMessages).forEach((key) => {
                        commit('addSyncError', {
                            guid,
                            key,
                            // @ts-ignore
                            errorUserMessage: error.errorAttributeUserMessages[key][0],
                        });
                    });
                    // @ts-ignore
                }
                else if (typeof error.errorFieldName === 'string' && error.errorFieldName.length > 0) {
                    commit('addSyncError', {
                        guid,
                        // @ts-ignore
                        key: error.errorFieldName,
                        errorUserMessage: Vue.i18n.translate('Ungültiger Wert.'),
                    });
                }
            });
        },
    };
}
export default actions;
