import _cloneDeep from 'lodash.clonedeep';
import { isRelativeUnit } from '@/activities/utils/amountsAndUnits/findUnit';
import AggregationService from '../../services/AggregationService';
import calcTimeOnField from './utils/distributeTimeToActivities/calcTimeOnField';
import initServices from './utils/initServices';
import updateValuesOnActivityProductInAllActivities, { addNewCopy, getFamily, updateProducts, } from './utils/updateValuesOnActivityProductInAllActivities';
export default function updateAmountAndUnitOnActivityProductInAllActivities(state, data) {
    if (isRelativeUnit(data.updateValues.unit)) {
        updateValuesOnActivityProductInAllActivities(state, {
            activityProductId: data.activityProductId,
            updateValues: {
                amount: data.updateValues.amount,
                unitId: data.updateValues.unit.id,
            },
            rootState: data.rootState,
        });
    }
    else {
        distributeTotalAmountToAllActivityProducts(state, data);
    }
}
function distributeTotalAmountToAllActivityProducts(state, data) {
    const { findService, activityProductService } = initServices(state, data.rootState);
    const aggregationService = new AggregationService(state.activities);
    const totalProcessedArea = aggregationService.sumProcessedArea;
    const originalActivityProduct = _cloneDeep(findService.findActivityProduct(data.activityProductId));
    if (!originalActivityProduct)
        return;
    state.activities.forEach((activity) => {
        const family = getFamily(activity, originalActivityProduct, activityProductService);
        if (family.length > 0) {
            updateTotalAmountOnProducts(family, { amount: data.updateValues.amount, unitId: data.updateValues.unit.id }, activity.processedArea, totalProcessedArea);
        }
        else {
            const clone = addNewCopy(activity, originalActivityProduct, data.updateValues);
            updateTotalAmountOnProducts([clone], { amount: data.updateValues.amount, unitId: data.updateValues.unit.id }, activity.processedArea, totalProcessedArea);
        }
    });
}
function updateTotalAmountOnProducts(products, updateValues, processedAreaOfActivity, totalProcessedArea) {
    const amountOnActivity = calcTimeOnField(updateValues.amount, processedAreaOfActivity !== null && processedAreaOfActivity !== void 0 ? processedAreaOfActivity : 0, totalProcessedArea);
    updateProducts(products, { amount: amountOnActivity, unitId: updateValues.unitId });
}
