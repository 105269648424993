export default function hiddenRows(tableData) {
    const rows = [];
    let currentReferenceRow = null;
    tableData.forEach((row, rowIndex) => {
        if (row.id !== (currentReferenceRow === null || currentReferenceRow === void 0 ? void 0 : currentReferenceRow.id)) {
            currentReferenceRow = row;
        }
        else if (currentReferenceRow !== null) {
            if ('expand' in currentReferenceRow && currentReferenceRow.expand === true) {
                return;
            }
            rows.push(rowIndex);
        }
    });
    return {
        indicators: true,
        rows,
    };
}
