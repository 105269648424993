/**
 *
 * @param value checks if value is a string of the form "1d 2h 3m 4s" each part is optional
 * @returns the number of seconds
 */
export default function parsePattern1d2h3m4s(value) {
    const parts = value.match(/(?:(\d+)?d\s?)?(?:(\d+)?h\s?)?(?:(\d+)?m\s?)?(?:(\d+)?s)?/);
    if (parts == null)
        return null;
    /* eslint-disable no-param-reassign */
    const seconds = parts.slice(1).reduce((acc, part, partIndex) => {
        const parsedValue = parseInt(part, 10);
        if (Number.isNaN(parsedValue))
            return acc;
        if (partIndex === 0) {
            acc += parsedValue * 3600 * 24;
        }
        else if (partIndex === 1) {
            acc += parsedValue * 3600;
        }
        else if (partIndex === 2) {
            acc += parsedValue * 60;
        }
        else if (partIndex === 3) {
            acc += parsedValue;
        }
        return acc;
    }, 0);
    /* eslint-enable no-param-reassign */
    if (seconds === 0)
        return null;
    return seconds;
}
