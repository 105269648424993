import { ApplicationType } from '../../types';
export default function buildPayload(fieldId, dbId, zoneMap, application) {
    const { applicationType } = application;
    switch (applicationType) {
        case ApplicationType.FIRST:
            try {
                return buildPayloadForApplicationTypeFirst(fieldId, dbId, zoneMap, application);
            }
            catch (_a) {
                return buildBasePayload(fieldId, dbId, zoneMap);
            }
        case ApplicationType.THIRD:
            try {
                return buildPayloadForApplicationTypeThird(fieldId, dbId, zoneMap, application);
            }
            catch (_b) {
                return buildBasePayload(fieldId, dbId, zoneMap);
            }
        default:
            return buildPayloadForVisualizationModeZone(fieldId, dbId, zoneMap);
    }
}
function buildPayloadForApplicationTypeFirst(fieldId, dbId, zoneMap, application) {
    return Object.assign(Object.assign({}, buildBasePayload(fieldId, dbId, zoneMap)), { fertilizerAdviceParams: buildFertilizerAdviceParamsWinterGrains1N(application) });
}
function buildFertilizerAdviceParamsWinterGrains1N(application) {
    const { applicationTypeFirst: { targetNRate }, } = application;
    if (targetNRate === null) {
        throw new Error('targetNRate must be set');
    }
    return {
        averageFertilizerRate: targetNRate,
    };
}
function buildPayloadForApplicationTypeThird(fieldId, dbId, zoneMap, application) {
    return Object.assign(Object.assign({}, buildBasePayload(fieldId, dbId, zoneMap)), { fertilizerAdviceParams: buildFertilizerAdviceParamsWinterGrains3N(application) });
}
function buildFertilizerAdviceParamsWinterGrains3N(application) {
    const { applicationTypeThird: { averageFertilizerRate1N, averageFertilizerRate2N, targetYield, humidityAreaType, targetGrainQuality, }, } = application;
    if (averageFertilizerRate1N === null) {
        throw new Error('averageFertilizerRate1N must be set');
    }
    if (averageFertilizerRate2N === null) {
        throw new Error('averageFertilizerRate2N must be set');
    }
    if (targetYield === null) {
        throw new Error('targetYield must be set');
    }
    if (humidityAreaType === null) {
        throw new Error('humidityAreaType must be set');
    }
    if (targetGrainQuality === null) {
        throw new Error('targetGrainQuality must be set');
    }
    return {
        averageFertilizerRate1N,
        averageFertilizerRate2N,
        expectedYield: targetYield,
        humidityAreaType,
        targetGrainQuality,
    };
}
function buildPayloadForVisualizationModeZone(fieldId, dbId, zoneMap) {
    return buildBasePayload(fieldId, dbId, zoneMap);
}
function buildBasePayload(fieldId, dbId, zoneMap) {
    const { visualizationMode, numberOfZones, borderZoneCorrection, zoningMode } = zoneMap;
    return {
        fieldId,
        dbId,
        visualizationMode,
        numberOfZones,
        borderZoneCorrection,
        zoningMode,
    };
}
