import Vue from 'vue';
import { combinedNameExclCrop, companyId, crop, customer, fieldNumber, name, size, status, variety, } from '@/fields/handsontable/columns/columns';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { tableSettingsColumn } from '@/shared/handsontable/mixins/featureTablesettingsColumn';
export const tableFields = [
    Object.assign(Object.assign({}, selectColumn), { hiddenInPageSettings: true, lockedVisibility: true, lockedPosition: true, readOnly: true }),
    Object.assign(Object.assign({}, companyId), { lockedVisibility: true, lockedPosition: true, requiredFeatures: (store) => store.state.auth.currentCompanies.length > 1 }),
    Object.assign(Object.assign({}, combinedNameExclCrop), { header: {
            title: () => Vue.i18n.translate('Kunde - Feldstück - Feld'),
            description: () => Vue.i18n.translate('Kunde - Feldstück - Feld'),
        }, width: 145, lockedPosition: true, sortOrder: 'asc', readOnly: true }),
    Object.assign(Object.assign({}, size), { key: 'processedAreaOrFieldSize' }),
    Object.assign(Object.assign({}, crop), { lockedPosition: false, readOnly: true }),
    Object.assign(Object.assign({}, variety), { readOnly: true }),
    Object.assign(Object.assign({}, fieldNumber), { hiddenPerDefault: true, readOnly: true }),
    Object.assign(Object.assign({}, customer), { hiddenPerDefault: true, readOnly: true }),
    Object.assign(Object.assign({}, name), { hiddenPerDefault: true, className: '', width: 145, readOnly: true }),
    Object.assign(Object.assign({}, status), { hiddenPerDefault: true, readOnly: true }),
    tableSettingsColumn,
];
export default {
    international: tableFields,
};
