import Handsontable from 'handsontable';
import LoadingRenderer from '../../../renderers/LoadingRenderer';
import EmptyRenderer from '../emptyRenderer/EmptyRenderer';
export default function OptionalRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties) {
    if (shouldRenderNone(visualRow, instance, cellProperties)) {
        Handsontable.dom.empty(TD);
        // eslint-disable-next-line no-param-reassign
        TD.style.display = 'none';
        return;
    }
    if (shouldRenderEmpty(visualRow, instance, cellProperties)) {
        EmptyRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties);
        return;
    }
    if (shouldRenderLoading(visualRow, value, instance, cellProperties)) {
        LoadingRenderer(instance, TD, visualRow, visualCol, prop, value, cellProperties);
        return;
    }
    if (typeof cellProperties.type !== 'string') {
        throw new Error('cellProperties.type is not a string');
    }
    const Renderer = Handsontable.renderers.getRenderer(cellProperties.type);
    Renderer(instance, TD, visualRow, visualCol, prop, value, cellProperties);
}
/* -- Conditions -- */
function shouldRenderNone(visualRow, instance, cellProperties) {
    return typeof cellProperties.renderNone === 'function' && cellProperties.renderNone(visualRow, instance);
}
function shouldRenderEmpty(visualRow, instance, cellProperties) {
    return typeof cellProperties.renderEmpty === 'function' && cellProperties.renderEmpty(visualRow, instance);
}
function shouldRenderLoading(visualRow, value, instance, cellProperties) {
    return typeof cellProperties.renderLoading === 'function' && cellProperties.renderLoading(visualRow, value, instance);
}
