var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { mapGetters } from 'vuex';
/**
 * Always use this mixin together with shared/handsontable/mixins/containers/tableContainerBase.
 *
 * This mixin automatically connects a handsontable container to a store that uses the subscribableData mixin.
 *
 * Check subscribableData store mixin for info about forceReset and forceRefresh.
 *
 * @param storeName {string}
 * @param forceReset {boolean}
 * @param forceRefresh {boolean}
 * @return {object}
 */
export default function featureSubscribableTableData(storeName, { forceReset = false, forceRefresh = false } = {}) {
    return {
        data() {
            return {
                subscribableDataUnsubscribe: () => null,
            };
        },
        computed: Object.assign({}, mapGetters({
            loading: `${storeName}/loading`,
            tableData: `${storeName}/data`,
            tableErrors: `${storeName}/errors`,
        })),
        mounted() {
            // @ts-ignore
            this.subscribableDataUnsubscribe = this.$store.subscribe(({ type }) => {
                if (type === 'afterResetData') {
                    // @ts-ignore
                    this.loadData();
                }
            });
            // @ts-ignore
            this.loadData();
        },
        beforeDestroy() {
            // @ts-ignore
            this.subscribableDataUnsubscribe();
        },
        methods: {
            loadData() {
                return __awaiter(this, void 0, void 0, function* () {
                    // #412 give the UI some time to catch up
                    // eslint-disable-next-line no-promise-executor-return
                    yield new Promise((resolve) => setTimeout(resolve, 30));
                    // @ts-ignore
                    yield this.$store.dispatch('auth/subscribe');
                    // @ts-ignore
                    yield this.$store.dispatch(`${storeName}/subscribe`, { forceReset, forceRefresh });
                    yield this.loadAdditionalData();
                });
            },
            /**
             * Use this to load additional data before the table will be rendered (i.e. this.loading = false).
             *
             * @override
             * @returns {Promise<void>}
             */
            loadAdditionalData() {
                return __awaiter(this, void 0, void 0, function* () {
                    return null;
                });
            },
        },
    };
}
