import Handsontable from 'handsontable';
export default function formatDuration(value) {
    if (value === null || !Handsontable.helper.isNumeric(value))
        return null;
    const unixTimestamp = Number(value);
    if (Number.isNaN(unixTimestamp) || unixTimestamp === 0)
        return '';
    const dateObj = new Date(unixTimestamp * 1000);
    const daysToHours = (dateObj.getUTCDate() - 1) * 24;
    const totalHours = dateObj.getUTCHours() + daysToHours;
    const seconds = dateObj.getUTCSeconds();
    const totalMinutes = dateObj.getUTCMinutes() + (seconds > 30 ? 1 : 0);
    const formattedTime = `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;
    return formattedTime;
}
