import Vue from 'vue';
import { baseUrl } from '@/shared/constants';
import { subscribableStore, syncableStore } from './common';
const getterTree = Object.assign(Object.assign(Object.assign({}, subscribableStore.getters), syncableStore.getters), { list(state) {
        return Object.values(state.data);
    },
    tableDataAssessments(state, getters, rootState, rootGetters) {
        return getters.list.map((e) => {
            var _a;
            let customer;
            if (e.fieldId) {
                const field = rootState.fields.data[e.fieldId];
                customer = rootState.customers.data[(_a = field.fieldGroup.customerId) !== null && _a !== void 0 ? _a : ''];
            }
            return Object.assign(Object.assign({}, e), { customerName: (customer === null || customer === void 0 ? void 0 : customer.name) || '', customerEmail: (customer === null || customer === void 0 ? void 0 : customer.email) || '', customerNumber: (customer === null || customer === void 0 ? void 0 : customer.customerNumber) || '', farmNumber: (customer === null || customer === void 0 ? void 0 : customer.bdmId) || '', occurrencesPerHa: e.additionalData.occurrencesPerHa, occurrencesTotal: e.additionalData.occurrencesTotal, assessmentName: e.additionalData.assessmentName, riskCategory: Vue.i18n.translate(e.additionalData.riskCategory), select: false, link: constructLink(e), 
                // TODO: improve syntax
                fieldDropdownItem: rootGetters['fields/dropdownItem'](e.fieldId) });
        });
    } });
function constructLink(assessment) {
    var _a;
    return ((_a = assessment.files) === null || _a === void 0 ? void 0 : _a.length) ? `${origin}${baseUrl}datura/drone/viewer/${assessment.files[0].id}` : '';
}
export default getterTree;
