var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue from 'vue';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
export function fetchAll({ rootGetters, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const { daturaApi } = Vue.prototype.$api;
        const processOrderId = rootGetters['auth/currentProcessOrderIds'][0];
        const promise = yield daturaApi.daturaImportJobList({ processOrderId });
        return toApiResponse(promise.data);
    });
}
function toApiResponse(response) {
    return Object.assign(Object.assign({}, response), { data: response.data });
}
function fetchByIds() {
    return __awaiter(this, void 0, void 0, function* () {
        throw new Error('Unsupported operation!');
    });
}
export const subscribableStore = subscribableData(fetchAll, fetchByIds);
