import { MoveActivityState as MoveActivityResult } from 'farmdok-rest-api';
const gettersModule = {
    moveActivityParametersWithoutMode: (state) => {
        if (state.moveActivityParametersWithResult) {
            return Object.values(state.moveActivityParametersWithResult).filter((moveActivityParameter) => !moveActivityParameter.mode);
        }
        return null;
    },
    moveActivityParametersWithConflict: (state) => {
        if (state.moveActivityParametersWithResult) {
            return Object.values(state.moveActivityParametersWithResult).filter((moveActivityParameter) => moveActivityParameter.result === MoveActivityResult.NoMatchFound);
        }
        return null;
    },
    moveActivityParametersWithSuccess: (state) => {
        if (state.moveActivityParametersWithResult) {
            return Object.values(state.moveActivityParametersWithResult).filter((moveActivityParameter) => moveActivityParameter.result === MoveActivityResult.CopyField ||
                moveActivityParameter.result === MoveActivityResult.ReplaceField);
        }
        return null;
    },
};
export default gettersModule;
